import { FC } from "react";
import styles from "./styles/styles.module.css";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const ContentsectionHeader: FC = () => {
  return (
    <div className={styles.wrapper}>
      <Breadcrumbs />
    </div>
  );
};

export default ContentsectionHeader;
