import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login/Login";
import styles from "./globalStyles/styles.module.css";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import Dashboard from "./pages/Dashboard/Dashboard";
import RedirectToDashboard from "./pages/Dashboard/util/RedirectToDashboard";
import Layout from "./layout/Layout";

function App() {
  return (
    <div className={styles.pageWrapper}>
      <BrowserRouter>
        <Routes>
          <Route path="/" Component={RedirectToDashboard} />
          <Route path="/login" Component={Login} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute
                component={() => (
                  <Layout>
                    <Dashboard />
                  </Layout>
                )}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
