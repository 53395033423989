// src/app/store.ts
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/AuthSlice";
import UserSlice from "./slices/UserSlice";
import BreadcrumbSlice from "./slices/BreadcrumbSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: UserSlice,
    breadcrumbs: BreadcrumbSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
