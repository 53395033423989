import { FC } from "react";
import styles from "./styles/styles.module.css";
import { useSelector } from "react-redux";
import { ReactComponent as MailLogo } from "../../assets/bannerMailLogo.svg";

const DashboardBanner: FC = () => {
  const { firstName, company } = useSelector((state: any) => state.user);

  return (
    <div className={styles.wrapper}>
      <div className={styles.leftContainer}>
        <p className={styles.topText}>{`Welcome, ${firstName}`}</p>
        <p className={styles.companyText}>{company}</p>
        <p className={styles.dashboardHeadline}>Email security dashboard </p>
        <button
          onClick={() => alert("Viewing Events")}
          className={styles.button}
        >
          View Events
        </button>
      </div>

      <div className={styles.rightContainer}>
        <div className={styles.imageContainer}>
          <MailLogo className={styles.mailLogo} />
        </div>

        <div className={styles.lineContainer}>
          <div className={styles.bannerLineThick}></div>
          <div className={styles.bannerLineThin}></div>
        </div>
      </div>
    </div>
  );
};

export default DashboardBanner;
