// src/features/auth/authSlice.ts
import { createSlice } from "@reduxjs/toolkit";

interface AuthState {
  email: string;
  tokenExpiration: any;
  firstName: string;
  lastName: string;
  role: string;
  company: string;
}

const initialState: AuthState = {
  email: "",
  tokenExpiration: null,
  firstName: "",
  lastName: "",
  role: "",
  company: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.email = action.payload.email;
      state.tokenExpiration = action.payload.tokenExpiration;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.role = action.payload.role;
      state.company = action.payload.company;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
