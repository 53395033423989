import React, { FC } from "react";
import DashboardBanner from "../../components/DashboardBanner/DashboardBanner";
import styles from "./styles/styles.module.css";
import DashboardChartCanvas from "../../components/DashboardChart/DashboardChartCanvas";

const Dashboard: FC<any> = () => {
  return (
    <div className={styles.wrapper}>
      <DashboardBanner />
      <div className={styles.chartContainer}>
        <DashboardChartCanvas
          headline="Incident detection this month:"
          numericData={1217}
          percentage={null}
          chartType="LINE"
        />
        <DashboardChartCanvas
          headline="Total emails scanned this month:"
          numericData={189012}
          percentage={null}
          chartType={"BAR"}
        />
        <DashboardChartCanvas
          headline="Emails flagged:"
          numericData={2457}
          percentage={1.3}
          chartType={"PIE"}
        />
      </div>
    </div>
  );
};

export default Dashboard;
