import { Navigate } from "react-router-dom";

/*
    if the base path is called, this function redirects to dashboard. 
    the dashboard will check the authentication and either grant access to dashboard
    or route to <Login />
*/

const RedirectToDashboard = () => {
  return <Navigate to="/dashboard" replace />;
};

export default RedirectToDashboard;
