// src/features/auth/authSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  isAuthenticated: boolean;
  storeSession: boolean;
}

const initialState: AuthState = {
  isAuthenticated: false, // Default to not authenticated
  storeSession: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthenticated(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
    clearAuthenticated(state) {
      state.isAuthenticated = false;
    },
    setStoreSession(state, action) {
      state.storeSession = action.payload.storeSession;
    },
  },
});

export const { setAuthenticated, clearAuthenticated } = authSlice.actions;

export default authSlice.reducer;
