// src/features/auth/authSlice.ts
import { createSlice } from "@reduxjs/toolkit";

interface BreadcrumbTypes {
  currentPage: string;
  route: string;
  breadcrumbs: string[];
}

const initialState: BreadcrumbTypes = {
  currentPage: "dashboard",
  route: "/dashboard",
  breadcrumbs: ["Home", "Settings", "Profile"],
};

const breadcrumbSlice = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    setRouteData(state, action) {
      state.currentPage = action.payload.page;
      state.route = action.payload.route;
      state.breadcrumbs = action.payload.breadcrumbs;
    },
  },
});

export const { setRouteData } = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;
