import { Bar } from "react-chartjs-2";
import "chart.js/auto"; // Automatically registers the necessary Chart.js components

const BarChart = () => {
  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "Layer 1",
        data: [10000, 12000, 8000, 15000, 17000, 14000],
        backgroundColor: "#1F47F4", // Darkest blue
        borderRadius: 10, // Rounded top
        barThickness: 18, // Bar width in pixels
      },
      {
        label: "Layer 2",
        data: [7000, 8000, 6000, 9000, 11000, 10000],
        backgroundColor: "#1F47F480", // Middle blue
        borderRadius: 10, // Rounded top
        barThickness: 18, // Bar width in pixels
      },
      {
        label: "Layer 3",
        data: [5000, 6000, 4000, 7000, 9000, 8000],
        backgroundColor: "#1F47F433", // Lightest blue
        borderRadius: 10, // Rounded top
        barThickness: 18, // Bar width in pixels
      },
    ],
  };

  const options = {
    responsive: true,
    animation: {
      duration: 0, // Disable animation
    },
    maintainAspectRatio: false,
    aspectRatio: 2,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true, // Enable stacking for x-axis
        grid: {
          display: false,
        },
        border: {
          display: false, // Hide the border line above x-axis
        },
        ticks: {
          color: "#818181",
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        stacked: true, // Enable stacking for y-axis
        display: false, // Hide the y-axis
      },
    },
    elements: {
      bar: {
        borderRadius: 10, // Rounded top for bars
        barThickness: 18, // Bar width in pixels
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default BarChart;
