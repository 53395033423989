import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import styles from "./styles/styles.module.css";
import { IoMdEye } from "react-icons/io";
import { PulseLoader } from "react-spinners";
import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
} from "amazon-cognito-identity-js";
import { poolData } from "../../config/awsConfig";

const userPool = new CognitoUserPool(poolData as any);

//cognito log in component
export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");
  const [boxChecked, setBoxChecked] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [userAttributes, setUserAttributes] = useState(null);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
  const [loading, setLoading] = useState(false);

  /* 
    if there is an active session, navigate to dashboard. 
    In the private [dashboard] route, the component will check the token
 */
  useEffect(() => {
    const localStorageSession = localStorage.getItem("cognitoSession");
    const sessionStorageSession = sessionStorage.getItem("cognitoSession");

    if (localStorageSession) {
      navigate("/dashboard", {
        replace: true,
        state: { cognitoSession: JSON.parse(localStorageSession) },
      });
    }

    if (sessionStorageSession) {
      navigate("/dashboard", {
        replace: true,
        state: { cognitoSession: JSON.parse(sessionStorageSession) },
      });
    }
  }, [navigate]);

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const authenticationData = {
      Username: email,
      Password: password,
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUserInstance = new CognitoUser(userData);

    try {
      if (!newPasswordRequired) {
        setLoading(true);
        cognitoUserInstance.authenticateUser(authenticationDetails, {
          onSuccess: (result) => {
            const tokens = {
              idToken: result.getIdToken().getJwtToken(),
              accessToken: result.getAccessToken().getJwtToken(),
              refreshToken: result.getRefreshToken().getToken(),
            };

            if (boxChecked) {
              localStorage.setItem("cognitoSession", JSON.stringify(tokens));
            } else {
              sessionStorage.setItem("cognitoSession", JSON.stringify(tokens));
            }

            setLoading(false);
            //navigate to dashboard <PrivateComponent /> after successful login
            navigate("/dashboard", {
              replace: true,
              state: { cognitoSession: tokens },
            });
          },
          onFailure: (err) => {
            setLoading(false);
            alert(`Error logging in: ${err.message || JSON.stringify(err)}`);
          },
          newPasswordRequired: (userAttributes, requiredAttributes) => {
            // User was authenticated but needs to set a new password
            // Remove non-mutable and unnecessary attributes
            delete userAttributes.email_verified;
            delete userAttributes.email;
            setNewPasswordRequired(true);
            setUserAttributes(userAttributes);
            setCognitoUser(cognitoUserInstance); // Store the cognitoUser instance
            setLoading(false);
            setPassword("");
            setPassword2("");
          },
        });
      } else if (newPasswordRequired && cognitoUser) {
        if (password === password2) {
          localStorage.removeItem("cognitoSession");
          sessionStorage.removeItem("cognitoSession");
          setLoading(true);
          cognitoUser.completeNewPasswordChallenge(password, userAttributes, {
            onSuccess: (result) => {
              setEmail("");
              setPassword("");
              setLoading(false);
              setNewPasswordRequired(false);
              alert("Password successfully changed!");
            },
            onFailure: (err) => {
              setLoading(false);
              alert(
                `Error changing password: ${err.message || JSON.stringify(err)}`,
              );
            },
          });
        } else {
          alert("The passwords do not match");
        }
      }
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.topContainer}>
        <Logo className={styles.logo} />
      </div>
      <div className={styles.loginContainer}>
        <div className={styles.mainContainerTop}>
          <p className={styles.loginHeadline}>Login to your account</p>
        </div>

        <hr className={styles.rule} />

        <div className={styles.mainContainerCenter}>
          <form className={styles.form} onSubmit={handleLogin}>
            {!newPasswordRequired ? (
              <div className={styles.inputGroup}>
                <label className={styles.label} htmlFor="email">
                  Email:
                </label>
                <input
                  pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                  required
                  type="email"
                  placeholder="Enter your email"
                  className={styles.inputEl}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  name="email"
                />
              </div>
            ) : (
              <div className={styles.inputGroup}>
                <label className={styles.label} htmlFor="password">
                  New Password:
                </label>
                <div className={styles.passwordContainer}>
                  <input
                    required
                    type={showPassword2 ? "text" : "password"}
                    placeholder="Enter your new password"
                    className={styles.inputEl}
                    onChange={(e) => setPassword2(e.target.value)}
                    value={password2}
                    name="password2"
                  />
                  <IoMdEye
                    onClick={() => setShowPassword2(!showPassword)}
                    className={styles.passwordToggleIcon}
                  />
                </div>
              </div>
            )}

            <div className={styles.inputGroup2}>
              <label className={styles.label} htmlFor="password">
                {!newPasswordRequired ? "Password:" : "Confirm Password:"}
              </label>
              <div className={styles.passwordContainer}>
                <input
                  required
                  type={showPassword ? "text" : "password"}
                  placeholder={
                    !newPasswordRequired
                      ? "Enter your password"
                      : "Re-enter your password"
                  }
                  className={styles.inputEl}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  name="password"
                />
                <IoMdEye
                  onClick={() => setShowPassword(!showPassword)}
                  className={styles.passwordToggleIcon}
                />
              </div>
            </div>

            <div className={styles.rememberMeContainer}>
              <div className={styles.checkboxContainer}>
                <input
                  checked={boxChecked}
                  onClick={() => setBoxChecked(!boxChecked)}
                  name="checkbox"
                  className={styles.checkbox}
                  type="checkbox"
                />
                <label className={styles.labelCheckbox} htmlFor="checkbox">
                  Remember me
                </label>
              </div>
              <p className={styles.forgotPassword}>Forgot Password</p>
            </div>
            <div className={styles.buttonContainer}>
              <button className={styles.loginButton}>
                {!loading ? (
                  !newPasswordRequired ? (
                    "Log in"
                  ) : (
                    "Update Password"
                  )
                ) : (
                  <PulseLoader
                    size={8}
                    color="white"
                    cssOverride={{ marginTop: "2px" }}
                  />
                )}
              </button>
            </div>
          </form>
        </div>

        <hr className={styles.rule} />

        <div className={styles.mainContainerBottom}>
          <span className={styles.contactUsContainer}>
            <p className={styles.getAccountStatement}>
              Don't have an account?{" "}
            </p>
            <p className={styles.contactUs}>Contact us</p>
          </span>
        </div>
      </div>
      <div className={styles.footerContainer}>
        <span className={styles.copyrightStatement}>
          <p>{`${"\u00A9"} 2024 Cygrade`}</p>{" "}
          <p className={styles.divider}>|</p>{" "}
          <p className={styles.link}>Privacy Policy</p>{" "}
          <p className={styles.divider}>|</p>{" "}
          <p className={styles.link}>Copyright</p>
        </span>
      </div>
    </div>
  );
}
