import React, { FC } from "react";
import { Pie } from "react-chartjs-2";
import "chart.js/auto"; // Automatically registers the necessary Chart.js components
import styles from "./styles/styles.module.css";

interface PieChartInterface {
  percentage: number;
  label: string;
}

const PieChart: FC<PieChartInterface> = ({ percentage, label }) => {
  const data = {
    labels: [label, ""],
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: ["#1F47F4", "#1F47F433"], // Primary color and light blue for the remainder
        hoverBackgroundColor: ["#1F47F4", "#1F47F433"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    cutout: "85%", // Adjust cutout to make the circle thinner
    elements: {
      arc: {
        borderWidth: 4, // Set the thickness of the arc
      },
    },
  };

  return (
    <div className={styles.pieChartContainer}>
      <div className={styles.chartWrapper}>
        <Pie data={data} options={options} />
        <div className={styles.pieChartLabel}>
          <p className={styles.percentage}>{percentage}%</p>
        </div>
      </div>
      <p className={styles.label}>{label}</p>
    </div>
  );
};

export default PieChart;
