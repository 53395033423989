import { FC } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto"; // Automatically registers the necessary Chart.js components

const LineChart: FC = () => {
  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "Incident detection this month",
        data: [1200, 1050, 100, 1100, 1400, 800],
        backgroundColor: "rgba(31, 71, 244, 0.2)", // Change to rgba of #1F47F4
        borderColor: "#1F47F4", // Change to #1F47F4
        borderWidth: 2,
        pointBackgroundColor: "#1F47F4", // Change to #1F47F4
        pointBorderColor: "#FFFFFF", // Optional: white border around the points for better visibility
        pointBorderWidth: 2, // Optional: border width of the points
      },
    ],
  };

  const options = {
    responsive: true,
    animation: {
      duration: 0, // Disable animation
    },
    maintainAspectRatio: false,
    aspectRatio: 2,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide grid lines
        },
        border: {
          display: false, // Hide the border line
        },
        ticks: {
          color: "#818181", // Change color to match the style
        },
      },
      y: {
        display: false, // Hide the y-axis
      },
    },
    elements: {
      line: {
        tension: 0, // Set tension to 0 for straight lines
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default LineChart;
