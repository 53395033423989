import React, { FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./styles/styles.module.css";
import { setRouteData } from "../../Redux/slices/BreadcrumbSlice";

interface MenuItemType {
  Logo: React.FC<React.SVGProps<SVGSVGElement>>;
  itemName: string;
  active: boolean;
  height: string;
  width: string;
  route: string;
  breadcrumbs: string[];
}

const MenuItem: FC<MenuItemType> = ({
  Logo,
  itemName,
  active,
  height,
  width,
  route,
  breadcrumbs,
}) => {
  const { currentPage } = useSelector((state: any) => state.breadcrumbs);
  const dispatch = useDispatch();

  function handleItemClick() {
    console.log(route);
    console.log(itemName);
    dispatch(
      setRouteData({
        page: itemName,
        route,
        breadcrumbs,
      }),
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.logoContainer}>
        <Logo
          onClick={handleItemClick}
          style={{
            minWidth: width,
            minHeight: height,
            width: width,
            height: height,
          }}
        />
      </div>
      <p
        onClick={handleItemClick}
        className={
          currentPage.toLowerCase() === itemName.toLowerCase()
            ? styles.menuTextActive
            : styles.menuText
        }
      >
        {itemName}
      </p>
    </div>
  );
};

export default MenuItem;
