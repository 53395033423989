import { FC, useState } from "react";
import styles from "./styles/styles.module.css";
import { ReactComponent as CaretRight } from "../../assets/CaretRight.svg";

interface subMenuItem {
  name: string;
  route: string;
}

interface MenuItemProp {
  Logo: React.FC<React.SVGProps<SVGSVGElement>>;
  name: string;
  color: string;
  subItems: subMenuItem[];
}

const ExpandableMenuItem: FC<MenuItemProp> = ({
  Logo,
  name,
  color,
  subItems,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      style={
        isExpanded
          ? { marginBottom: `${subItems.length * 33}px` }
          : { marginBottom: "0px" }
      }
      className={styles.wrapper}
      onClick={handleToggle}
    >
      <div
        style={
          isExpanded
            ? { transform: "rotate(90deg)" }
            : { transform: "rotate(0deg)" }
        }
        className={styles.caret}
      >
        <CaretRight />
      </div>

      <div style={{ backgroundColor: color }} className={styles.logoWrapper}>
        <Logo />
      </div>

      <div className={styles.nameContainer}>
        <p className={styles.name}>{name}</p>
        <div
          style={isExpanded ? { opacity: 1 } : { opacity: 0 }}
          className={styles.subContainer}
        >
          {subItems.map((item: subMenuItem, index: number) => (
            <p key={index} className={styles.subItem}>
              {item.name}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExpandableMenuItem;
