import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

// Define a type for commonly used API response structure, if known
interface ApiResponse<T> {
  data: T;
  message?: string;
}

// const getToken = () => {
//   const localStorageSession = JSON.parse(
//     localStorage.getItem("cognitoSession") as any,
//   );
//   const sessionStorageSession = JSON.parse(
//     sessionStorage.getItem("cognitoSession") as any,
//   );
//   return localStorageSession?.idToken || sessionStorageSession?.idToken;
// };

// Creating an axios instance with base configuration
const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_SERVER,
  headers: {
    "Content-Type": "application/json",
  },
});

// Set up request interceptors if needed
api.interceptors.request.use((request) => {
  // You can modify the request here before it is sent, e.g., attaching auth tokens
  return request;
});

// Set up response interceptors
api.interceptors.response.use(
  (response) => {
    // Handle global response behavior here
    return response;
  },
  (error) => {
    // Handle global errors
    return Promise.resolve(error.response);
  },
);

// Function to perform GET request
const get = async <T>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<ApiResponse<T>> => {
  const response: AxiosResponse<ApiResponse<T>> = await api.get(url, config);
  return response.data;
};

// Function to perform POST request
const post = async <T>(
  url: string,
  data: any,
  config?: AxiosRequestConfig,
): Promise<ApiResponse<T>> => {
  const response: AxiosResponse<ApiResponse<T>> = await api.post(
    url,
    data,
    config,
  );
  return response.data;
};

// Function to perform PUT request
const put = async <T>(
  url: string,
  data: any,
  config?: AxiosRequestConfig,
): Promise<ApiResponse<T>> => {
  const response: AxiosResponse<ApiResponse<T>> = await api.put(
    url,
    data,
    config,
  );
  return response.data;
};

// Function to perform DELETE request
const del = async <T>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<ApiResponse<T>> => {
  const response: AxiosResponse<ApiResponse<T>> = await api.delete(url, config);
  return response.data;
};

export const Api = {
  get,
  post,
  put,
  delete: del,
};
