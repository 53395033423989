import { useSelector } from "react-redux";
import styles from "./styles/styles.module.css";

const Breadcrumbs = () => {
  const { breadcrumbs } = useSelector((state: any) => state.breadcrumbs);
  return breadcrumbs.map((crumb: string, index: number) => (
    <span className={styles.wrapper} key={index}>
      <span
        className={
          index === breadcrumbs.length - 1 && breadcrumbs.length > 1
            ? styles.lastCrumb
            : styles.regularCrumb
        }
      >
        {crumb}
      </span>
      {index < breadcrumbs.length - 1 && (
        <span
          className={
            index === breadcrumbs.length - 2
              ? styles.lastSlash
              : styles.regularSlash
          }
        >
          {" / "}
        </span>
      )}
    </span>
  ));
};

export default Breadcrumbs;
