import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { verifyToken } from "../../api/auth";
import { useSelector, useDispatch } from "react-redux";
import {
  setAuthenticated,
  clearAuthenticated,
} from "../../Redux/slices/AuthSlice";
import { setUser } from "../../Redux/slices/UserSlice";

/* 
 This component will either redirect to the protected component
 or will will redirect to "/login" if the token was not verifiable
*/
const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const { isAuthenticated } = useSelector((state: any) => state.auth);

  useEffect(() => {
    const authenticate = async () => {
      console.log("This was called");
      try {
        const storedSession =
          JSON.parse(localStorage.getItem("cognitoSession") as any) ||
          JSON.parse(sessionStorage.getItem("cognitoSession") as any) ||
          location.state.cognitoSession;

        const token = storedSession?.idToken;
        const verify = await verifyToken(token);
        if (!(verify as any).success) {
          dispatch(clearAuthenticated());
          dispatch(setAuthenticated(false));
          localStorage.removeItem("cognitoSession");
          sessionStorage.removeItem("cognitoSession");
        } else {
          const userObject = {
            firstName: (verify as any).user.firstName,
            lastName: (verify as any).user.lastName,
            role: (verify as any).user.role,
            company: (verify as any).user.companyName,
            email: (verify as any).user.email,
            tokenExpiration: (verify as any).user.exp,
          };

          dispatch(setUser(userObject));
          dispatch(setAuthenticated(true));
          console.log("We are now authenticated!!");
        }
      } catch (error) {
        dispatch(clearAuthenticated());
        dispatch(setAuthenticated(false));
      } finally {
        setIsLoading(false);
      }
    };
    if (!isAuthenticated) {
      authenticate();
    }
  }, [location, isAuthenticated, dispatch]);

  if (isLoading) {
    return <div>Loading...</div>; // TEST response time AND CHANGE FOR APPROPRIATE LOOKING LOADER
  }

  return isAuthenticated ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
